<template>
  <div class="card-container mt-1 ">
    <div v-if="header" class="card-header row">
      <slot name="header"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Card",
  computed: {
    header() {
      return this.$slots.header;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Card";
</style>
