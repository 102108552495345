<template>
  <div :class="position + 'badge'">{{ label }}</div>
</template>

<script>
export default {
  name: "Badge",
  props: {
    label: { type: String, default: "" },
    position: { type: String, default: "" },
  },
};
</script>

<style lang="scss" scoped>
@import "/Badge";
</style>
